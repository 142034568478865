.cardBanner {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin:15px;
    @media (max-width: 991px) {
      max-width: 100%;
      flex-direction: column;
  }
    .cardBanner-section {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative; 
        display: flex;
        max-height: 436px;
        width: 100%;
        align-items: center;
        padding: 44px 54px;
        border-radius: 10px;
        @media (max-width: 991px) {
          max-width: 100%;
          min-height: 236px;
          padding: 44px 0;
      }
      }

      

      .image {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .header {
        position: relative;
        color: var(--Gray-Scale-Gray-900, #1a1a1a);
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0.42px;
        text-transform: uppercase;
      }

      .description {
        position: relative;
        color: var(--Gray-Scale-Gray-900, #1a1a1a);
        align-self: stretch;
        margin-top: 25px;
        font: 600 40px Poppins, sans-serif;
      }

      .percentage-container {
        position: relative;
        display: flex;
        margin-top: 18px;
        gap: 12px;
        font-size: 18px;
        white-space: nowrap;
        line-height: 150%;
      }
      .description{
      @media (max-width: 991px) {
        font-size: 24px;
        }
      }
      .cardBanner-section-color{
        .header, .description, .percentage-label{
            color: #fff;
          
        }
      
      }

      @media (max-width: 991px) {
        .percentage-container {
          white-space: initial;
        }
      }

      .percentage-label {
        color: var(--Gray-Scale-Gray-900, #1a1a1a);
        font-weight: 400;
        flex-grow: 1;
        margin: auto 0;
      }

      @media (max-width: 991px) {
        .percentage-label {
          white-space: initial;
        }
      }

      .percentage {
        border-radius: 5px;
        background-color: var(--Gray-Scale-Gray-900, #1a1a1a);
        flex-grow: 1;
        justify-content: center;
        color: #fcc900;
        font-weight: 600;
        padding: 6px 12px;
      }

      @media (max-width: 991px) {
        .percentage {
          white-space: initial;
        }
      }

      .shop-container {
        position: relative;
        justify-content: center;
        border-radius: 43px;
        background-color: var(--Gray-Scale-White, #fff);
        display: flex;
        gap: 12px;
        color: var(--Branding-Success, #00b207);
        font-weight: 600;
        text-align: justify;
        margin: 24px 0 196px;
        padding: 14px 32px;
      }

      @media (max-width: 991px) {
        .shop-container {
          margin-bottom: 40px;
      
        }
      }

      .shop-img {
        aspect-ratio: 1.15;
        object-fit: auto;
        object-position: center;
        width: 15px;
        margin: auto 0;
      }
}