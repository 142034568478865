.product-card {
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 23%;
    margin-top: 24px;
    line-height: 1.5;
    position: relative;
    @media (max-width: 991px) {
        width:32%
    }
    @media (max-width: 520px) {
        width:100%
    }
    .cartDiscount{
        position: absolute;
        background: green;
        color: #fff;
        padding: 10px;
        right: 0;
        border-radius: 10px;
        margin:10px;
        z-index: 9;
    }
    .product-image{
        height: 250px;
        position: relative;
        display:flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-bottom:1px solid #f1f1f1;
        background:#f1f1f1;

    }
    .product-image img {
        max-width: 100%;
        max-height: 100%;
    }
    
    .product-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
    }
    
    .product-name {
        font-size: 16px !important;
        color: #000;
        margin: 0;
    }
    .stock-container{
        display: flex;
        justify-content: space-around;
        width:100%;
    }
    .product-stock{
        font-size: 14px !important;
        color:#f58f11
    }

    .product-mrp {

        color: #999;
        text-decoration: line-through;
        margin: 6px 0;
      }
      .product-special-price {

        color: #1a1a1a;
        font-weight: 500;
      }
    
    .product-price {

        color: #747373;
        font-weight: 500;
        text-decoration: line-through;
    }

    .priceContainer{
        display: flex;
        width:100%;
        justify-content: space-around;
        p{
            font-size: 16px !important;
            color:#000
        }
    }
    
    .product-icon {
        width: 60px;
        aspect-ratio: 5;
        margin-top: 6px;
    }
    
    
}

.product-mrp {

    color: #999;
    text-decoration: line-through;
    margin: 6px 0;
  }
  .product-special-price {    color: #1a1a1a;
    font-weight: 500;

  }

.product-price {
    color: #747373;
    font-weight: 500;
    text-decoration: line-through;
}

.priceContainer{
    display: flex;
    width:80%;
    justify-content: space-around;
  
}

.quantityControl {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .quantityDisplay {
    font-size: 1.25rem;
    color: #000;
  }

.pdp{
    display: flex;
    @media (max-width: 991px) {
        align-items: flex-end;
        flex-direction: column-reverse;


    }
    > div{
        width: 50%;
        @media (max-width: 991px) {
            width: 100%;
        }
    }
}
.pdpRight .productDetail{
        display: flex;
        flex-direction: column;
        margin:0 20px;
        @media (max-width: 991px) {
            margin:0;
            margin-bottom: 20px;
         }
        .productDetail-header {
            display: flex;
            gap: 8px;
          }
        .productDetail-content{
            display: flex;
            justify-content: space-around;
        }
    
      .stock-status {
        justify-content: center;
        border-radius: 4px;
        align-items: center;
        display: flex;
        background-color: rgba(32, 181, 38, 0.2);
        color: #2c742f;
        padding: 4px 8px;
        font: 400 14px/150% Poppins, sans-serif;
      }
      .discountContainer {
        border-radius: 30px;
        background-color: rgba(234, 75, 72, 0.1);
        justify-content: center;
        color: var(--Branding-Error, #ea4b48);
        margin: auto 0;
        padding: 3px 10px;
        font: 500 14px 'Poppins', sans-serif;
        @media (max-width: 991px) {
            .discountContainer {
              white-space: initial;
            }
          }
      }

      
      .product-description{
        padding: 10px  0;
        font-size: 13px;
      }
}
.p-galleria-thumbnail-item{
    overflow: hidden;
    margin:0 2px 0 0 ;
    border:1px solid white;
    @media (max-width: 991px) {
        margin:0 ;
        border:0;
    }
}
.p-galleria-thumbnail-item-content img{
    height: 50px !important;
}

.videoGallery{
    margin-top:30px;
   iframe{
        width: 45%;
        margin:10px;
        height: 300px;
        @media (max-width: 991px) {
            width: 100%;
            margin:10px 0;
            height: 300px;
        }
   }
}
@media (max-width: 991px) {
.p-dialog{
    width: 96% !important;
}
}

.p-galleria-thumbnail-container{
    background: rgba(0,0,0,.6);
}