.bannerContainer{
    display: flex;
    min-height:300px;
    @media (max-width: 991px) {
        flex-direction: column;
    }
    
}
.noData{
    padding:5%;
}
.contentContainer{
    text-align: left;
    margin: 2% 5%;
    .contactSocialMedia{
        text-align: left;
    }
    .contactContent{
        display: flex;
        > div{
            width:50%;
            font-size: 13px;
            span{
                color: #ef5705;
            }
            
        }
        .rightContent{
            display: flex;
            flex-direction: column;
        }
        .contact-whatapp{
           display:flex;
           font-size: 20px;
         margin:20px 0;
           justify-content: center;
           align-items: center;
        }
        @media (max-width: 767px) {
            flex-direction: column-reverse;
            > div{
                width:100%;
            }
        }
    }
}
.products-display{
    gap:2%;
    width:100%;
    display: flex;
    flex-wrap: wrap;
}


i.pi{
    font-size: 20px !important;
}

i.pi-times{
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
}

.place-order-btn
{
  justify-content: center;
  align-items: center;
  border-radius: 43px;
  background-color: #00b207;
  margin-top: 20px;
  color: #fff;
  white-space: nowrap;
  padding: 16px 60px;
  font: 600 16px/120% Poppins, sans-serif;
  border: none;
  cursor: pointer;
}

.categoryHeader{
    width:100%;
    display: flex;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    z-index: 1000;
    background: green;
    align-items: center;
    color: #fff;
    padding: 0 10px;
    h1{
        margin:0
    }
    .selectedCategory{
        color: #e78f2a;
    }
    select{
        height: 45px;
    }
    @media (max-width: 767px) {
        top: 52px;
       h1{
        display: none;;
       }
    }
    
}