.cart {
  margin-bottom: 20px;

  .cartContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 30px 0;
    justify-content: flex-start;

    @media (max-width: 991px) {
      flex-direction: column;
    }

    .cartLeft {
      width: 66%;
      margin: 0 2%;
      @media (max-width: 991px) {
        width: 96%;
      }

      .product-container {
        border-radius: 8px;
        border: 1px solid rgba(230, 230, 230, 1);
        background-color: #fff;
        display: flex;
        flex-direction: column;
        padding: 17px 0;
      }

      .product-header {
        display: flex;
        margin-left: 20px;
        max-width: 100%;
        justify-content: space-between;
        gap: 20px;
        font-size: 14px;
        color: #808080;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.42px;
      }

      .separator {
        border-top: 1px solid #e6e6e6;
        margin: 15px 0px;
      }

      .cart-product-list {
        margin: 12px 20px 0;
        max-height: 460px;
        overflow-y: auto;
        @media (max-width: 767px) {
          margin: 12px 5px 0;
        }
      }
      @media (max-width: 767px) {
      .cartItemHeader, .cartItemHeader + .separator{
        display: none;
      }
    }

      .product-item {
        display: flex;
        gap: 20px;
        @media (max-width: 767px) {
          flex-wrap: wrap;
        }
      }

      .product-image-wrapper {
        display: flex;
        gap: 12px;
        font-size: 16px;
        color: #1a1a1a;
        align-items: center;
        padding-left: 30px;
        width:40%;
        @media (max-width: 767px) {
          padding-left: 0;
          width:100%;
        }
  
      }

      .product-image {
        aspect-ratio: 1;
        object-fit: cover;
        width: 70px;
        border: 1px solid #e6e6e6;
        margin-top: 5px;
       
  
      }

      .product-name {
        margin: auto 0;
        font-size: 13px;
      }

      .product-pricing {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        width: 100%;
      }

      .price,
      .subtotal {
        color: #1a1a1a;
      }

      .quantity-selector {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
      }

      .decrease-quantity,
      .increase-quantity {
        cursor: pointer;
        border-radius: 50%;
        background-color: #f2f2f2;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
      }

      .actions {
        display: flex;
        justify-content: space-between;
        padding: 16px 20px;
        gap: 20px;
      }

      .action-return,
      .action-update {
        cursor: pointer;
        background-color: #f2f2f2;
        padding: 14px 32px;
        border-radius: 43px;
      }
      .emptyCart{
        padding: 10% 0;
      }
      .product-price, .product-special-price {
        width:20%;
      }
    }

    .cartRight {
      width: 26%;
      margin: 0 2%;
      @media (max-width: 991px) {
        width: 96%;
      }

      .cart-container {
        border-radius: 8px;
        border: 1px solid rgba(230, 230, 230, 1);
        background-color: #fff;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        width: 100%;
        padding: 28px 24px;
        margin-bottom: 20px;
      }

      .cart-title {
        color: #00b207;
        padding-bottom: 20px;
        font-size: 20px;
      }

      .costs-container,
      .shipping-container,
      .total-container {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        gap: 20px;
        line-height: 150%;
        padding: 12px 0;
      }

      .subtotal-label,
      .shipping-label,
      .total-label {
        color: #4d4d4d;
      }
      .total-label-red, .total-value-red {
        color: red;
        font-size: 14px;
      }

      .subtotal-value,
      .shipping-value,
      .total-value {
        color: #1a1a1a;
      }

      .total-value,
      .total-label {
        font-weight: 800;
        font-size: 18px;
      }

      .discount {
        .subtotal-label,
        .subtotal-value {
          color: #00b207 !important;
        }
      }

      .checkout-btn {
        justify-content: center;
        align-items: center;
        border-radius: 43px;
        background-color: #00b207;
        margin-top: 20px;
        color: #fff;
        white-space: nowrap;
        padding: 16px 60px;
        font: 600 16px/120% Poppins, sans-serif;
        border: none;
        cursor: pointer;
      }

      @media (max-width: 991px) {

        .costs-container,
        .shipping-container,
        .total-container {
          white-space: initial;
        }

        .checkout-btn {
          padding: 0 20px;
        }
      }
    }
  }

  .product-item {

    .price {
      width: 40%;
      display: flex;
      justify-content: center;
      gap: 5px;
    }

    .quantity-selector {
      width: 30%;
    }

    .quantity {
      font-size: 15px;
    }

  }
  

  .billing-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 0 30px;

    >div {
      padding: 10px 0;

      span {
        color: #808080;
      }
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .form-control {
    border-radius: 6px;
    border: 1px solid rgba(230, 230, 230, 1);
    padding: 19px 16px;
    font-size: 13px;
    color: var(--Gray-Scale-Gray-400, #999);
    background-color: var(--Gray-Scale-White, #fff);
  }

  .select-control {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .selector-icon {
    width: 16px;
    margin-left: auto;
  }

  .visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
  }

  .billing-title,
  .additional-title {
    font-size: 24px;
    color: var(--Gray-Scale-Gray-900, #1a1a1a);
  }

  .select-label,
  .company-label {
    font-size: 14px;
    color: var(--Gray-Scale-Gray-500, #808080);
  }

  @media (max-width: 991px) {

    /* Responsive styling adjustments */
    .billing-form,
    .form-control {
      max-width: 100%;
      margin-bottom: 20px;
    }

    .form-group {
      flex-direction: column;
    }
  }

  .checkoutForm {
    display: flex;
    flex-wrap: wrap;

    >div {
      width: 46%;
      margin: 2%;
      @media (max-width: 991px) {
        width: 96%;
      }
    }
  }

  .payment {

    .order-summary-title,
    .payment-method-title {
      font-weight: 500;
      font-size: 20px;
      color: #1a1a1a;
      margin-bottom: 12px;
    }

    .product-list,
    .payment-method,
    .subtotal-section,
    .total-section,
    .shipping-section {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .payment-method {
      margin: 0px 0 20px 25%;
      align-items: flex-start;

      label {
        padding-left: 10px;
      }
    }

    .product-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .product-image {
      width: 60px;
      aspect-ratio: 1;
      object-fit: cover;
    }

    .product-mrp {

      color: #999;
      text-decoration: line-through;
      margin: 6px 0;
    }

    .product-special-price {

      color: #1a1a1a;
      font-weight: 500;
    }

    .product-price {

      color: #747373;
      font-weight: 500;
      text-decoration: line-through;
    }

    .product-details {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .product-name {
      flex-grow: 1;
    }

    .product-price,
    .subtotal-amount,
    .shipping-amount,
    .total-amount {
      font-weight: 500;
    }

    .dividing-line {
      border-top: 1px solid rgba(230, 230, 230, 1);
      margin: 12px 0;
    }

    .payment-option {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .payment-indicator {
      width: 20px;
      height: 20px;
      border: 2px solid;
      border-color: #00b207;
      border-radius: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .payment-indicator::before {
      content: '';
      width: 12px;
      height: 12px;
      background-color: #00b207;
      border-radius: 32px;
    }

    .selected .payment-indicator {
      background-color: #00b207;
    }

    .place-order-btn {
      justify-content: center;
      align-items: center;
      background-color: #00b207;
      color: #fff;
      padding: 16px 60px;
      font-weight: 600;
      border-radius: 43px;
      border: none;
      cursor: pointer;
      font-size: 16px;
      margin-top: 24px;
    }

    @media (max-width: 991px) {
      .place-order-btn {
        padding: 16px 20px;
      }
    }
  }

  .user-details-container {
    border-radius: 6px;
    border: 1px solid rgba(230, 230, 230, 1);
    background-color: #fff;
    font-size: 14px;
    width: 100%;

    .section-title {
      color: #999;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.42px;
      text-transform: uppercase;
      margin-left: 20px;
      margin-top: 20px;
    }

    .divider {
      border-top: 1px solid #e6e6e6;
      margin-top: 19px;
    }
  }

  .codeSucess{
    display: block;
    color:#00b207;
    padding:10px;
    font-size: 13px;
  }
  .codeError{
    color: red;
    display: block;
    padding: 10px;
    font-size: 13px;
  }

  .saveAddressButton {
    button {
      width: 300px;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .editAddressButton {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .orderBtnActive {
    background-color: #00b207;
  }

  .orderBtnDeactive {
    background-color: #313331;
  }
}



.orderConfirmContainer {
  margin: 5% 0;
  h3{
    font-size: 18px !important;
  }
  h1{
    font-size: 20px !important;
  }
  .title {
    color: #00b207;
    letter-spacing: 0.36px;
    text-transform: uppercase;
  }
  @media (max-width: 767px) {
    h3{
      font-size: 16px !important;
    }
    h1{
      font-size: 14px !important;
    }
    .title {
      font-size: 18px !important;
    }
  }
  
}