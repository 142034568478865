.footer {
  background-color: #1a1a1a;
  color: #999;
  padding: 20px 60px 0;

  @media (max-width: 991px) {
      padding: 20px 20px 0;
      text-align: center;
  }

  .footer-content {
    width: 100%;
    margin: auto;
    padding: 0 0 20px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-direction: column;
    }

    .footerMenu {
      display: flex;
      width: 50%;
      justify-content: space-between;

      @media (max-width: 991px) {
          display: flex;
          width: 100%;
          padding-top:20px;
          margin-top:10px;
          border-top:1px solid #333;
      }
      @media (max-width: 767px) {
        flex-wrap: wrap;
        section{
          width: 50%;
          margin-bottom: 10px;
        }
        section:last-child{
          width: 100%;
        }
      }
    }

  }

  .information {
    flex: 1;
    .footerLogo{
      display: flex;
      align-items: center;
      justify-content: center;
      .logo{
        max-width:90px;
      }
    }

  }

  

  .brand-name {
    color: #fff;
    font-weight: 500;
    margin: 0;
    span{
      font-size: 24px !important;
      @media (max-width: 767px) {
        font-size: 15px !important;
      }
    }
  }

  .description {
    margin-top: 16px;
    color: #808080;
  }

  .contact-info {
    display: flex;
    margin-top: 16px;
    justify-content: center;
    @media (max-width: 767px) {
    flex-direction: column;
    }
  }

  .phone,
  .email {
    background-color: #1a1a1a;
    padding: 6px 0;
  }

  .separator {
    margin: 0 10px;
    color: #808080;
  }

  .footer-bottom {
    background-color: #1a1a1a;
    color: #808080;
    display: flex;
    justify-content: center;
    border-top: 1px solid #333;

    @media (max-width: 991px) {
      flex-direction: column-reverse;
      margin-top: 10px;
      justify-content: center;
    }
  }

  .navigation {
    display: flex;
  }

  .section-title {
    color: #fff;
    margin: 0;
  }

  .navigation-list {
    list-style: none;
    padding: 0;
  }

  .navigation-list li {
    margin-top: 8px;
    a{
      color: #808080;
      ;
    }
  }

  .social-media-icons {
    display: flex;
    gap: 8px;

    @media (max-width: 991px) {
      justify-content: center;
      margin-top: 10px;
    }
  }

  .icon {
    width: 45px;
    aspect-ratio: 1.41;
  }
}

.whatapp{
  position: fixed;
  bottom: 15%;
  right:0;
}

.footerCartItem{
  display: none;
}
@media (max-width: 991px) {
.footerCartItem{
  display: block;
  background-color: #e78f2a;
  padding:10px 0;
  position: fixed;
  bottom:0;
  width:100%;
  a{
    color:#000 !important;
  }
}
}

.footerpoint{
  font-size: 12px;
}