.adminHeader{
    background-color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 18px;
        color: #002603;
        font-weight: 500;
        letter-spacing: -0.96px;
        line-height: 0;
        text-decoration: none;
        cursor: pointer;
    
        .logo {
          max-width: 80px;
        }
        h1{
          margin-left: -28px;
          span{
            font-size: 18px;
          }
        }
      }
    div{
        color: #fff;
    }
} 
.adminLeftMenu{
  display: flex;
  flex-direction: column;
  padding-top:20px;
  a{
    color: #fff;
    text-decoration: none;
    padding:15px 10px;
    margin-bottom: 5px;
  }
  a:hover, a.active{
    background-color: #000;
  }
}

.adminForm{
  border:1px solid #f1f1f1;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2% 5%;
  gap:10px;
  background-color: #faf5f5;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .form-group{
    display: flex;
    flex-direction: column;
    margin:10px 0;
    width:48%;
    @media (max-width: 767px) {
      width:98%;
    }
    input{
      padding:10px;
      width: 100%;
    }
   
  }
  .submitButton{
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

.groupitems{
  background-color: #f1f1f1;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .displayGroup{
    display: flex;
    justify-content: space-around;
    width:80%;
    padding: 10px;
    flex-wrap: wrap;
    overflow: hidden;
  }

}

.p-datatable .p-datatable-tbody > tr > td{
  padding:5px;
}

.prdTable{
  margin-bottom: 10px;
  border-bottom: 1px dashed;
}
.tableProduct > div, .tableProduct{
  font-size:10px !important;
}

.mobileAdminMenu{
  display: flex;
  justify-content: center;
  align-items: center;
  .pi-bars{
    color:#fff;
    margin-left:20px;
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
  
}

.mobileLeftMenu{
  position: absolute;
  top: 45px;
  background:#e78f2a;
  z-index: 1;
  display: none;
  width: 100%;
  a{
    width: 100%;
    display: block;
    padding: 10px;
    text-decoration: auto;
    color: #fff;
    font-size: 14px !important;
  }
  a.active{
    background-color: #000;
  }
  @media (max-width: 991px) {
    display: block;
  }
}

.pieChart{
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  canvas{
    width: 400px;
    height: 400px;
    @media (max-width: 520px) {
      width: 300px;
      height: 300px;
    }
  }
}

.messageContainer{
  width:100%;
  margin-top:20px;
  tr:nth-last-child(3), tr:nth-last-child(2), tr:nth-last-child(1){
    td{
    background-color: #68aa6e;
    }
  }
  td{
    width:30%;
    padding:10px;
    background-color: #f1f1f1;
    @media (max-width: 767px) {
      width:100%;
    }
    textarea{
      width:100%;
      height:220px;
    }
  }
  @media (max-width: 767px) {
    tr{
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
  
}
.downloadCSV {
  margin-top:30px;
  a{
  font-size: 20px;
  }
}