.mainBanner {
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  min-height: 380px;
  flex-grow: 1;
  color: #fff;
  margin: 1%;
}

@media (max-width: 991px) {
    margin-top: 24px;
    padding: 0 20px;
}

.backgroundImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.titleContainer {
  position: relative;
  margin-top: 180px;
  width: 400px;
  font-size: 2.5em;
    color: #fff; /* Text color */
    border: 3px solid #fff; /* Border around the text */
    display: inline-block; /* Ensure border wraps the text */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Shadow effect */
    background-color: #ff6600; /* Background color behind text for better visibility */
}

@media (max-width: 991px) {
  .titleContainer {
    max-width: 50%;
    margin-top: 100px;
    font-size: 40px;
  }
}

.promotion {
  position: relative;
  display: flex;
  margin-top: 28px;
  gap: 12px;
  line-height: 150%;
  margin-left: 5%;
  color:#000;

  @media (max-width: 991px) {
    .promotion {
      gap: 8px;
    }
  }

  .highlightIndicator {
    background-color: #84d187;
    width: 2px;
    height: 65px;

  }

  .promotionDetails {
    display: flex;
    flex-direction: column;
  }

  .promotionText {
    display: flex;
    gap: 8px;
    font-size: 20px;
  }

  .saleText {
    font-weight: 500;
  }

  .discountAmount {
    background-color: #000;
    color:#fff;
    border-radius: 5px;
    font-weight: 600;
    padding: 4px 12px;
  }

  .shippingInfo {
    margin-top: 8px;
    font: 400 14px Poppins, sans-serif;
  }

  .actionContainer {
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-radius: 53px;
    margin-top: 28px;
    padding: 18px 40px;
    font-size: 16px;
    color: #00b207;
    font-weight: 600;
  }



  .arrowIcon {
    width: 15px;
    align-self: center;
  }
}

.sideBanner{
  width:30%;
  display: flex;
  flex-direction: column;
  padding: 0 0.5% 1% 0;
  @media (max-width: 991px) {
    width:100%;
  }
}
.promotionalBanner {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  justify-content: center;

  align-items: center;
  text-align: center;
  min-height: 288px;
  margin-top: 4%;
  border-radius: 10px;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
 
  .banner-image {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #1a1a1a;
  }

  .heading {
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.42px;
    text-transform: uppercase;
    color: #fff;
    margin-top: 8px;
    background: #d71906;
    padding: 10px;
    border-radius: 5px;
    font-size: 18px;
  }

  .sub-text {
    font-weight: 400;
    line-height: 150%;
    color: #000;
    margin-top: 12px;
  }

  

  @media (max-width: 991px) {
    .banner {
      padding: 0 20px;
    }

    .heading,
    .sub-text {
      white-space: initial;
      color: #fff;
      font-size: 16px !important;
    }
  }
}

.shop-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00b207;
  border: none;
  border-radius: 43px;
  color: #fff !important;
  cursor: pointer;
  padding: 8px 12px;
  font-size: 16px;
  margin: 24px 0;
  text-decoration: none;
}
.shop-button:hover {
  background-color: #056409;
  color: #fff;
}