.homeHeader {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  white-space: nowrap;


  @media (max-width: 991px) {
    .header {
      max-width: 100%;
      white-space: initial;
      padding: 0 20px;
    }
  }

  .navigation {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 767px) {
        max-width: 100%;
        flex-wrap: wrap;
        white-space: initial;
        flex-direction: column;
    }
  }


  .contactInfo {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    line-height: 150%;
    align-items: center;
    margin-right: 2%;
    @media (max-width: 767px) {
      display: none;
    }
    .contactIcon {
      width: 40px;
      aspect-ratio: 1;
      object-fit: cover;
    }

    .contactDetails {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .contactTitle {
      color: #999;
      font-size: 14px;
      margin: 0;
    }

    .contactNumber {
      color: #1a1a1a;
      font-size: 20px;
      margin: 0;
    }

  }

  .logo-container a {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 24px;
    color: #002603;
    font-weight: 500;
    letter-spacing: -0.96px;
    line-height: 0;
    text-decoration: none;

    .logo {
      max-width: 90px;
    }
    h1{
      margin-left: -28px;
    }
  }
}

.navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  line-height: 150%;
  width: 100%;
  padding: 5px 5% 5px 2%;
  .menulistContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  a{
    color:#fff;
    text-decoration: none;
    margin: 0 3%;
  }
  
  a:hover{
    color:#e78f2a;
    text-decoration: none;
  }

  @media (max-width: 991px) {
    .navigation-bar {
      flex-wrap: wrap;
      padding: 0 20px;
    }
  }

  .menu-item {
    display: flex;
    gap: 4px;
    white-space: nowrap;
    padding: 0 2%;
    font-weight: 400;
    @media (max-width: 520px) {
      margin:0 10px 0 0 !important;
      padding:0 !important;
      font-size: 11px !important;
    }
  }

  .menu-text {
    flex-grow: 1;
  }

  .menu-icon {
    width: 16px;
    aspect-ratio: 1;
    object-fit: contain;
  }

  .headerCartCount{
    position: relative;
    span{
      position: absolute;
      right: -7px;
      top:-10px;
      width:20px;
      font-size: 11px;
      height: 20px;
      background:#80a902;
      border-radius:10px;
    }
  }

}


.benefits-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0px 8px 40px 0px rgba(0, 38, 3, 0.08);
  background-color: var(--Gray-Scale-White, #fff);
  width: 100%;
  padding: 10px 0;
  margin-bottom: 10px;
  @media (max-width: 991px) {
      max-width: 100%;
  }
  .benefit-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-basis: 100%;
    max-width: 25%;
    gap: 16px;
    @media (max-width: 991px) {
      margin-top: 20px;
      flex-direction: column;
    }
  }
  
  .benefit-image {
    width: 40px;
    height: auto;
  }
  .benefit-title {
    font-weight: 600;
    font-size: 16px;
    color: var(--Gray-Scale-Gray-900, #1a1a1a);
  }
  .benefit-description {
    font-weight: 400;
    font-size: 14px;
    color: var(--Gray-Scale-Gray-400, #999);
    margin-top: 8px;
  }
}

.storeLocation{
  padding:5px 0;
  border-bottom:1px solid #f8f6f6;
  select{
    border:0;
  }
  span{
    padding-left: 10px;
  }
}
.locationDetailsContainer{
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
  >section{
    width:60%;
    @media (max-width: 767px) {
      width:100%;
    }
  }
  iframe{
    height:450px;
    @media (max-width: 992px) {
      height:350px;
    }
  }
  .locationDetails{
    width:40%;
    margin:10px;
    @media (max-width: 767px) {
      width:100%;
    }
    p{
      font-size: 13px;
    }
  }
  h4{
    border:3px solid rgb(223, 168, 66);
    padding:10px;
    background: #dfa;
  }
}

.sticky{
  top: 0;
  position:sticky;
  background-color: #333;
  z-index: 100;
  transition: box-shadow 0.3s ease;
  a{
    display: flex;
    align-items: center;
  }
  img{
    max-width: 95px;
  }
}
.stickyLogo{
  color: #80a902;
  margin:0;
}