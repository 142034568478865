.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a:link {
  color: green;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: green;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

*, body{
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  font-family: "DynaPuff", system-ui;
  font-weight: 400!important;
  
}

a:hover{
  color:#80a902;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.green{
  color:#80a902
}
.orange{
  color:#e78f2a
}
.red{
  color:#ef5705
}

h1, h2{
  font-weight: 400;
}
h4{
  font-size: 14px !important;
}
button, label, .p-component *{
  font-size: 13px;
}
.p-dialog-title{
  font-size: 15px !important;
}
.p-tabview-title{
  font-size: 14px !important;
}

.pi-shopping-cart{
  padding-right: 10px;
}

@media (max-width: 992px) {
  *, body, .locationDetails h4{
    font-size: 12px !important;
  }
  .logo-container h1 span{
    font-size: 20px;
  }
  .p-dialog-title{
    font-size: 13px !important;
  }
  .storeLocation > select{
    width:80%;
  }
  .titleContainer, .promotionalBanner h2, .cardBanner .header{
    font-size: 40px !important;
  }
}

@media (max-width: 767px) {
  *, body{
    font-size: 11px;
  }
  .homeHeader .logo-container a{
    letter-spacing: 1!important;
  }
  .homeHeader .logo-container a .logo {
    max-width: 120px !important;
    
  }
  .logo-container h1 span{
    font-size: 18px !important;
  }
  .sticky{
    padding: 15px 20px 15px 10px !important;
  }
  .sticky > a{
    display: none !important;
  }
  .titleContainer, .promotionalBanner h2,.cardBanner .header{
    font-size: 20px !important;
  }
  .titleContainer{
    text-align: left;
    padding-left: 5%;
  }
}

/*admin*/

.adminContainer{
  width:100%;
  display: flex;
}
.adminLeftSection{
  width:10%;
  background-color: #e78f2a;
  color: #fff;
  height: 100vh;
}

.adminRightSection{
  width:90%;
}

@media (max-width: 991px) {
  .adminLeftSection{
  display: none;
  width: 0%;
  }
  .adminRightSection{
    width:100%;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Full viewport height */
  width: 100%; /* Full viewport width */
  position: absolute;
  z-index: 1; /* Ensure it overlays other content */
}

/* Loader */
.loader {
  border: 5px solid rgba(0, 0, 0, 0.1); /* Light border */
  border-radius: 50%;
  border-top: 5px solid #05b649; /* Blue color */
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

/* Spin Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}






