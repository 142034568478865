.carouselBanner{
    position: relative;
    margin:10px;
    min-height:100px;

      .carousel-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .carousel-item-content {
        text-align: center;
        max-height: 250px;
      }
      .carousel-image {
        width: 100%;
      }
      .carousel-name {
        margin-top: 14px;
        font-weight: bold;
        position: absolute;
        z-index: 9;
        top: 0;
        color: #fff;
        width: 100%;
    font-size: 30px;
    padding-left: 30%;
    padding-right: 10%;
    line-height: 40px;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    padding-top: 5%;
    @media (max-width: 991px) {
      line-height: 20px;
      padding-top: 2%;
    }
      }
}

.p-carousel-prev, .p-carousel-next{
  display: none !important;
}