.orderDetailContainer{
    display: flex;
    @media (max-width: 767px) {
      flex-wrap: wrap;
  }
    .orderLeft{
        width:76%;
        margin:0 2%;
        @media (max-width: 767px) {
          width:96%
      }
    }
    .orderRight{
        width:36%;
        margin:0 2%;
        @media (max-width: 767px) {
          width:96%
      }
    }
    .user-details-container {
      border-radius: 6px;
      border: 1px solid rgba(230, 230, 230, 1);
      background-color: #fff;
      display: flex;
      font-size: 14px;
      width: 100%;
      margin-bottom: 20px;
    }
    .billing-info, .shipping-info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0 auto;
    }
    .section-title {
      color: #999;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.42px;
      text-transform: uppercase;
      margin-left: 20px;
      margin-top:20px;
    }
    .divider {
      border-top: 1px solid #e6e6e6;
      margin-top: 19px;
    }
    .user-info {
      display: flex;
      flex-direction: column;
      color: #1a1a1a;
      font-weight: 400;
      line-height: 150%;
      margin-top: 20px;
      padding: 0 13px;
    }
    .user-name, .user-email, .user-phone {
      font-weight: 500;
    }
    .user-address, .email-text, .phone-text {
      color: #666;
      margin-top: 4px;
    }
    .user-image {
      object-fit: cover;
      width: 100%;
      max-width: 100px; /* Example width, adjust as needed */
      height: auto;
      margin: 20px;
    }
    @media (max-width: 991px) {
      .user-details-container {
        flex-wrap: wrap;
      }
      .section-title, .user-name, .user-email, .user-phone, .user-address, .email-text, .phone-text {
        margin-left: 10px;
        white-space: initial;
      }
    }
    .order-container {
      border-radius: 6px;
      border: 1px solid rgba(230, 230, 230, 1);
      display: flex;
      flex-grow: 1;
      padding: 18px;
      flex-direction: column;
      white-space: nowrap;
    }
    @media (max-width: 991px) {
      .order-container {
        margin-top: 24px;
        white-space: initial;
      }
    }
    .order-details {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      padding-bottom: 10px;
    }
    .billing-details {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        flex-direction: column;
        padding-top: 20px;
        > div{
            flex-direction: row;
            justify-content: space-between;
        }
      }
    @media (max-width: 991px) {
      .order-details, .billing-details {
        flex-direction: column;
      }
    }
    .order-id, .payment-method, .subtotal, .discount, .shipping, .total {
      display: flex;
      flex-direction: column;
    }
    .title {
      color: #00b207;
      letter-spacing: 0.36px;
      text-transform: uppercase;
    }
    .title-heading {
      color: #000;
      letter-spacing: 0.36px;
      text-transform: uppercase;
    }
    .order-id .value, .payment-method .value{
      font-size: 13px;
      margin-top:15px;
    }
    .value, .price {
      color: #1a1a1a;
    }
    .separator {
      border: 1px solid rgba(230, 230, 230, 1);
      background-color: #e6e6e6;
      height: 1px;
    }
    
    .product-container {
      border-radius: 8px;
      border: 1px solid rgba(230, 230, 230, 1);
      background-color: #fff;
      display: flex;
      flex-direction: column;
      padding: 17px 0;
    }

    .product-header {
      display: flex;
      margin-left: 20px;
      max-width: 100%;
      justify-content: space-between;
      gap: 20px;
      font-size: 14px;
      color: #808080;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.42px;
    }

    .separator {
      border-top: 1px solid #e6e6e6;
      margin: 15px 0px;
    }

    .cart-product-list {
      margin: 12px 20px 0;
      max-height: 460px;
      overflow-y: auto;
      @media (max-width: 767px) {
        margin: 12px 5px 0;
      }
    }
    @media (max-width: 767px) {
    .cartItemHeader, .cartItemHeader + .separator{
      display: none;
    }
  }

    .product-item {
      display: flex;
      gap: 20px;
      padding:0 20px;
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
    }

    .product-image-wrapper {
      display: flex;
      gap: 12px;
      font-size: 16px;
      color: #1a1a1a;
      align-items: center;
      padding-left: 30px;
      width:40%;
      @media (max-width: 767px) {
        padding-left: 0;
        width:100%;
      }

    }

    .product-image {
      aspect-ratio: 1;
      object-fit: cover;
      width: 70px;
      border: 1px solid #e6e6e6;
      margin-top: 5px;
     

    }

    .product-name {
      margin: auto 0;
      font-size: 13px;
    }

    .product-pricing {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
      width: 100%;
      span{
        display: none;
        @media (max-width: 767px) {
          display: block;
          flex-direction: column;
        }
      }
    }
    @media (max-width: 767px) {
    .product-price, .product-special-price, .quantity, .subtotal{
      display: flex;
      flex-direction: column;
    }
  }

    .price,
    .subtotal {
      color: #1a1a1a;
    }

    .quantity-selector {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
    }

    .decrease-quantity,
    .increase-quantity {
      cursor: pointer;
      border-radius: 50%;
      background-color: #f2f2f2;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }
    

      .order-process-container {
        align-self: center;
        display: flex;
        margin-top: 12px;
        width: 100%;
        max-width: 842px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 40px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        line-height: 150%;
        padding: 0 20px;
       
        .trackstatus{
          background: #adafad;
          color: #000;
          padding:10px;
          width: 50%;
          position: relative;
          &::before{
            content: '';
            height: 210%;
            width: 5px;
            background-color: #adafad;
            left: -50%;
            position: absolute;
            top: 50%;
          }
          &::after{
            content: '';
            height: 5px;
            width: 50%;
            background-color: #adafad;
            left: -50%;
            position: absolute;
            top: 50%;
          }
        }
        .trackstatus:last-child{
          &::before{
            display: none;
          }
        }
        .active{
          background: #00b207 !important;
          &::after, &::before{
            background-color: #00b207 !important;
          }
        }
      }
      @media (max-width: 991px) {
        .order-process-container {
          max-width: 100%;
          flex-wrap: wrap;
        }
      }
    
      .order-processing {
        font-weight: 500;
      }
      .order-in-transit, .order-delivered {
        color: var(--Gray-Scale-Gray-800, #333);
      }
      .active {
        opacity: 1;
      }
      .order-cancel{
        background-color: red;
        color: white;
        padding: 10px;
      }
      .order-sucess{
        background-color: green;
        color: white;
        padding: 10px;
      }

  }

  .productDownload{
    margin: 20px 0;
    a{
      color:#fff;
    }
  }